:root {
  --text_primary: #ff4f28;
  --bg-soft-primary: #ffede9;
  --login-alert-green-bg: #f6ffed;
  --login-alert-green-border: #b7eb8f;

  --alert-color-red-bg: #fff2f0;
  --alert-color-red-border: #ffccc7;
  --alert-color-red-text: #ff4d4f;

  --alert-color-green-text: #52c41a;
  --alert-color-green-hover: #eaf6de;

  --alert-color-blue-bg: #e6f4ff;
  --alert-color-blue-border: #91caff;
  --alert-color-blue-hover: #d6e9f9;
  --alert-color-blue-text: #1677ff;

  --alert-color-yellow-bg: #fffbe6;
  --alert-color-yellow-border: #ffe58f;
  --alert-color-yellow-text: #faad14;
  --alert-color-yellow-hover: #fbf3cf;
}
@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYREGULAR.OTF") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYMEDIUM.OTF") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYBOLD.OTF") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF") format("opentype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYTHINITALIC.OTF") format("opentype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF") format("opentype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYLIGHTITALIC.OTF") format("opentype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYHEAVYITALIC.OTF") format("opentype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFPRODISPLAYBLACKITALIC.OTF") format("opentype");
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: "SF Pro Display", sans-serif !important;
  margin: 0;
  padding: 0;
  background-color: #0c2337 !important;
  color: #fff;
}
.ultra-light-italic {
  font-weight: 200;
  font-style: italic;
}

.thin-italic {
  font-weight: 100;
  font-style: italic;
}

.semi-bold-italic {
  font-weight: 600;
  font-style: italic;
}

.light-italic {
  font-weight: 300;
  font-style: italic;
}

.heavy-italic {
  font-weight: 800;
  font-style: italic;
}

.black-italic {
  font-weight: 900;
  font-style: italic;
}

.font-weight-regular {
  font-weight: normal !important;
  font-style: normal !important;
}

.font-weight-medium {
  font-weight: 500 !important;
  font-style: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
  font-style: normal !important;
}
.alert {
  position: relative !important;
  top: 10px !important;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 101 !important;
}

.alert.alert-position:empty {
  width: 0;
}
.alert-position {
  position: fixed !important;
  z-index: 1052 !important;
}

.btn-primary {
  background: linear-gradient(
    20deg,
    rgba(254, 79, 40, 1) 0%,
    rgba(12, 35, 55, 1) 54%
  );
  border-color: var(--text_primary) !important;
  color: white !important;
}
body .btn-outline-primary {
  background: transparent !important;
  border-color: var(--text_primary) !important;
  color: var(--text_primary) !important;
}
.btn-outline-primary:hover {
  background: linear-gradient(
    20deg,
    rgba(254, 79, 40, 1) 0%,
    rgba(12, 35, 55, 1) 54%
  ) !important;
  color: white !important;
  transition: all 0.5s ease-in-out;
}
header {
  text-align: center;
  margin: 20px 0;
}

header h1 {
  font-size: 2.5em;
  color: #ff5a5f;
}

header p {
  font-size: 1.2em;
  margin: 5px 0;
}
.navbar-toggler.collapsed {
  background-color: #fff !important;
}
.search-bar input {
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 90%;
  max-width: 300px;
  margin-top: 10px;
}
.offcanvas-body .input-group {
  border: 1px solid #000 !important;
  border-radius: 5px !important;
  margin-top: 15px;
}
.main-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
}

.sidebar {
  padding: 15px;
  min-width: 300px;
}
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar ul li {
  margin: 10px 0;
}

body .text-primary {
  color: var(--text_primary) !important;
}
body .bg-primary {
  background-color: #0c2337 !important;
}
.sidebar ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 1.1em;
  display: block;
  padding: 7px 25px;
}

.sidebar ul li.active a {
  color: var(--text_primary);
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  font-weight: 600;
}

.navbar li a {
  font-weight: 700;
  color: #fff;
}
.navbar li a.active {
  color: var(--text_primary);
  font-weight: 700;
}
.content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 20px;
  flex: 1;
  justify-content: center;
}

.card {
  background-color: #fff;
  color: #000;
  width: 85%;
  border-radius: 10px;
  overflow: hidden;
  margin-left: 70px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-icon {
  padding: 0px 0;
  background-color: #f1f1f1;
  position: absolute;
  display: flex;
  width: 105px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  left: 10px;
  top: 40px;
  z-index: 46;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px;
}
.card-icon img {
  border-radius: 10px;
}
.card-content {
  text-align: left;
  padding: 20px 20px 20px 60px;
  min-height: 375px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-content h2 {
  margin-top: 0;
  font-size: 22px;
  font-weight: 700;
  color: #ff5a5f;
}

.card-content p {
  margin: 10px 0;
  font-size: 16px;
}

.card-content .description {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}

.card-content button {
  border: 1px solid var(--text_primary);
  color: var(--text_primary);
  background-color: var(--bg-soft-primary);
  padding: 10px 20px;
  width: 100%;
  font-size: 1em;
  border-radius: 10px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  margin-top: 10px;
}

.card-content button:hover {
  background-color: #fff;
  transition: all 0.4s ease-in-out;
}
.sidebar {
  top: 132px !important;
}
.header,
.sidebar {
  position: sticky !important;
  top: 0px;
  z-index: 100;
  background: #0c2337;
}
.header a {
  font-weight: 700;
  color: #fff !important;
}
.header a.active {
  color: var(--text_primary) !important;
}
.nav-link.active {
  color: var(--text_primary) !important;
  font-weight: 500 !important;
}
/*muzz-code*/
.form-box {
  width: 380px;
  min-height: 450px;
  background-color: black;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 2%;
}
.header-form {
  margin-bottom: 15px;
}
.form-box button {
  margin-top: 40px;
  margin-bottom: 10px;
}
.message {
  display: flex;
  justify-content: space-between;
}
.social {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.social i {
  font-size: 35px;
}

.social i:hover {
  color: red;
  cursor: pointer;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .main-content {
    flex-direction: column;
    align-items: center;
  }

  .sidebar {
    width: 100%;
    margin-bottom: 20px;
  }

  .content {
    margin-left: 0;
  }

  .card {
    width: 90%;
  }
}

.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.section {
  margin-bottom: 20px;
}

.user-info,
.change-password,
.book-list {
  padding: 10px;
  color: #000;
  border-radius: 4px;
}

.book-item {
  padding: 10px;
  border-bottom: 1px solid #000;
}
.shadow-soft {
  box-shadow: 0px 3px 6px 0px rgba(140, 152, 164, 0.25) !important;
}
.bg-red-soft {
  background-color: rgba(244, 67, 54, 0.1);
}
.bg-soft-alert-blue {
  background: var(--alert-color-blue-hover);
}

.bg-soft-alert-yellow {
  background: var(--alert-color-yellow-hover);
}

.bg-soft-alert-green {
  background: var(--alert-color-green-hover);
}
.alert-red {
  border-color: var(--alert-color-red-border) !important;
  background: var(--alert-color-red-bg);
}
.alert-icon {
  color: var(--alert-color-red-text);
}
.alert-green {
  border-color: var(--login-alert-green-border) !important;
  background: var(--login-alert-green-bg);
}
.alert-icon {
  color: var(--alert-color-green-text);
}
.alert-blue {
  border-color: var(--alert-color-blue-border) !important;
  background: var(--alert-color-blue-bg);
}
.alert-icon {
  color: var(--alert-color-blue-text);
}
.alert-yellow {
  border-color: var(--alert-color-yellow-border) !important;
  background: var(--alert-color-yellow-bg);
}
.alert-icon {
  color: var(--alert-color-yellow-text);
}
.icon-hover-bg,
.btn.btn-icon.btn-sm:not(.btn-ghost-primary):not(.btn-success):not(
    .icon-ignore
  ) {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  text-align: center;
  transition: all linear 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--icon-text-color) !important;
}
.icon-hover-bg:hover {
  background-color: #eee !important;
  color: #444 !important;
  border-radius: 50%;
}

.font-size-2xl {
  font-size: 1.75rem !important;
}
.fs-5 {
  font-size: 1.25rem !important;
}
.fs-7 {
  font-size: 13px;
}
.fs-20 {
  font-size: 20px;
}

.fs-9 {
  font-size: 11px !important;
}

.fs-10 {
  font-size: 10px;
}

.fs-11 {
  font-size: 9px;
}

.fs-12 {
  font-size: 8px;
}

.fs-13px {
  font-size: 13px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.booking-detail h2 {
  color: white;
  font-weight: 700;
  font-size: 60px;
  width: 99%;
}
.author p {
  color: #78818c !important;
}
.booking-detail p {
  color: #78818c;
}
.home-section {
  color: white;
}

.home-section h1 {
  font-weight: 900;
}

.trash {
  position: absolute;
  right: 35px;
  top: 25px;
  z-index: 12;
}
.landing-page-buttons button {
  font-size: 17px;
  font-weight: 500;
  font-style: normal;
  padding: 10px 25px;
  border-radius: 12px;
  border: 1px solid;
}
.landing-page-buttons button:first-child {
  background: linear-gradient(
    20deg,
    rgba(254, 79, 40, 1) 0%,
    rgba(12, 35, 55, 1) 54%
  );
  border-color: rgb(254, 79, 40);
  color: #fff !important;
}

.landing-page-buttons button:nth-child(1):hover {
  background: linear-gradient(
    190deg,
    rgba(254, 79, 40, 1) 0%,
    rgba(12, 35, 55, 1) 54%
  ) !important;
  transition: all 1s ease-in-out;
}
body .bg-grenadian {
  background: linear-gradient(
    20deg,
    rgba(254, 79, 40, 1) 0%,
    rgba(12, 35, 55, 1) 54%
  ) !important;
}
.btn-guardian {
  background: linear-gradient(
    20deg,
    rgba(254, 79, 40, 1) 0%,
    rgba(12, 35, 55, 1) 54%
  );
  border-color: rgb(254, 79, 40);
  color: #fff !important;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  padding: 14px 35px;
  border-radius: 12px;
  border: 1px solid;
}
.banner-section h1 {
  font-size: 100px;
  width: 67%;
  color: #0c2337;
}
.banner-section p {
  color: #9295b0;
  font-size: 20px;
  width: 40%;
}
.booking-detail button {
  font-weight: 600;
  font-style: normal;
  font-size: 19px;
}
.user-tabs .nav-tabs {
  display: flex;
  align-items: center;
  width: 100%;
  border: none !important;
}
.user-tabs .nav-tabs .nav-item {
  width: 50%;
}
.user-tabs .nav-tabs .nav-item:first-child button {
  border-top-left-radius: 13px !important;
  border-bottom-left-radius: 13px !important;
}
.user-tabs .nav-tabs .nav-item:nth-child(2) button {
  border-top-right-radius: 13px !important;
  border-bottom-right-radius: 13px !important;
}
.user-tabs .nav-tabs button {
  background: #122235;
  width: 100%;
  border-radius: 0px !important;
  color: white;
  padding: 16px;
  margin: 0px !important;
  font-weight: 300 !important;
  font-style: normal;
  font-size: 19px;
  border: 0px !important;
}
.user-tabs .nav-link.active {
  background: var(--text_primary) !important;
  color: white !important;
  border: none !important;
}
.user-tabs input {
  height: 55px;
  border-radius: 10px;
}
.divide {
  position: relative;
  text-align: center;
  color: #747474;
  margin-top: 15px;
}
.divide::before {
  content: "";
  width: 45%;
  position: absolute;
  height: 1px;
  top: 50%;
  background: #c7c7c7;
  left: 0px;
}
.divide:after {
  content: "";
  width: 45%;
  position: absolute;
  height: 1px;
  top: 50%;
  background: #c7c7c7;
  right: 0px;
}
.bg-transparent {
  background-color: transparent !important;
}
.logo {
  width: 50%;
}
.logo img {
  width: 300px;
  padding-left: 5px;
}
@media screen and (max-width: 1400px) {
  .banner-section h1 {
    font-size: 70px;
    width: 80%;
  }
  .booking-detail h2 {
    font-size: 50px;
  }
}
@media screen and (max-width: 900px) {
  .banner-section h1 {
    font-size: 50px;
    width: 100%;
    padding: 0px 15px;
  }
  .booking-detail h2 {
    font-size: 50px;
  }
  .booking-detail button {
    font-size: 14px;
  }
  .banner-section p {
    font-size: 16px;
    width: 100%;
    padding: 0px 15px;
  }
}

@media screen and (max-width: 650px) {
  .user-tabs .nav-tabs button,
  .btn-primary {
    font-size: 15px !important;
  }
  .card-icon {
    left: -10px !important;
  }
  .card-content {
    padding: 20px 20px 20px 70px !important;
  }
  .card {
    margin-left: 40px !important;
  }
  .landing-page-buttons button {
    font-size: 13px;
    font-weight: 300;
    font-style: normal;
    padding: 5px 11px;
    border-radius: 12px;
    border: 1px solid;
  }
  .banner-section h1 {
    font-size: 35px !important;
  }
  .btn-guardian {
    font-size: 16px !important;
  }
  .logo {
    width: 50%;
    padding-left: 5px;
  }
  .logo img {
    width: 200px !important;
  }
  .user-info .card {
    margin-left: 0px !important;
  }
}
.opacity-1 {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.opacity-0 {
  opacity: 0;
  height: 0px !important;
  transition: opacity 0.2s ease-out;
}
